import { useMemo, useRef, useState } from 'react'
import { Pagination, Autoplay, Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import useListGame from '@pig-casino/hooks/useListGame'
import Image from 'next/image'
import { gamesApi } from '@pig-casino/api'
import { pageLink } from '@pig-casino/constants/page-name'
import { userAgentType } from '@pig-common/types/Device.type'
import styles from './index.module.scss'
import isEmpty from 'lodash/isEmpty'
import styled from 'styled-components'
import { screen } from '@pig-common/utils/responsive'
import { useRouter } from 'next/router'

interface IGameBannerProps {
  userAgent?: userAgentType
}
const MainBannerSwiper = styled(Swiper)`
  margin-bottom: 12px;
  background-color: var(--color-greyscale-200);
  overflow: hidden;
  width: 100%;
  height: 324px;
  @media ${screen.md} {
    height: 620px;
  }
  @media ${screen.lg} {
    height: 390px;
  }
  @media ${screen.xl} {
    height: 490px;
  }
  @media only screen and (min-width: 1920px) {
    height: 690px;
  }
`
const SwiperPagination = styled.div`
  position: absolute;
  bottom: 8px;
  left: 0;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  gap: 10px;
  margin: 12px auto;
  span {
    background-color: var(--color-greyscale-100);
    width: 10px;
    height: 10px;
    margin: 0;
  }
`

export default function GameBanner({ userAgent }: IGameBannerProps) {
  const router = useRouter()
  const { data: highlightGame = [] } = useListGame({
    key: [gamesApi.key.highlight],
    params: { filterType: 'highlight' },
  })
  const [currentSlide, setSlide] = useState<number>(0)
  const [errorImage, setErrorImage] = useState<string[]>([])
  const swiperContainer = useRef(null)
  const onSlideChanged = (e: any) => {
    return e.realIndex >= 0 && setSlide(e.realIndex)
  }
  const displayBanner = useMemo(() => {
    const result = highlightGame.filter((game: any) => {
      const hasImage = game?.lobbyBannerUrl
      const isErrorImage = errorImage.find((url) => game.lobbyBannerUrl === url)
      return hasImage && !isErrorImage
    })
    return result
  }, [highlightGame, errorImage])
  const renderSlider = useMemo(() => {
    if (isEmpty(displayBanner)) return null
    return (
      <>
        {Array.isArray(displayBanner) &&
          displayBanner.map(
            (
              {
                gameName: title,
                lobbyBannerUrl: imageSrc,
                gameId: gameCode,
                gameProvider: refType,
              },
              i,
            ) => {
              return (
                <SwiperSlide key={`${refType}-${gameCode}`}>
                  <div
                    onClick={() =>
                      router.push(
                        `${pageLink.game}/${title.replace(/ /g, '-')}`,
                      )
                    }
                    onKeyDown={() =>
                      router.push(
                        `${pageLink.game}/${title.replace(/ /g, '-')}`,
                      )
                    }
                    className={styles['game-banner']}
                  >
                    {title}
                    <Image
                      priority
                      className="ps-banner-carousel__image"
                      fill
                      src={imageSrc}
                      alt={`${title} Banner`}
                      style={{
                        objectFit: 'cover',
                      }}
                      quality={75}
                      onError={() =>
                        setErrorImage((prev) => [...prev, imageSrc])
                      }
                    />
                  </div>
                </SwiperSlide>
              )
            },
          )}
      </>
    )
  }, [displayBanner, currentSlide])

  return (
    <div className={styles.container}>
      <MainBannerSwiper
        modules={[Navigation, Pagination, Autoplay]}
        slidesPerView={1}
        navigation
        pagination={{
          enabled: true,
          clickable: true,
          bulletClass: 'swiper-pagination-bullet ps-swiper-pagination-bullet',
          el: swiperContainer.current,
        }}
        autoplay={{
          delay: 5000,
          waitForTransition: true,
        }}
        onLoad={() => setSlide(0)}
        onSlideChange={(swiper) => onSlideChanged(swiper)}
        loop
      >
        {renderSlider}
        <SwiperPagination ref={swiperContainer} />
      </MainBannerSwiper>
    </div>
  )
}
